/** Basic reset for all */

* {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html {
  -mobile-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust: none;

  font-size: $base-font-size;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
}

:focus {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
small {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

figure {
  margin: 0;
}

body,
input,
textarea,
button {
  font: 400 1rem 'Roboto', sans-serif;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

svg-icon {
  line-height: 0;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
