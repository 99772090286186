::-webkit-scrollbar {
  width: 10px !important;
  border-radius: 20px !important;
  position: absolute !important;
  right: 0 !important;
}

::-webkit-scrollbar-track {
  background: color('neutral-hight-pure-medium') !important;
}

::-webkit-scrollbar-thumb {
  background: color('neutral-hight-pure-dark') !important;
  border-radius: 20px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: color('neutral-hight-pure-dark') !important;
}
