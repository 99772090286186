/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/index';
@import '@angular/cdk/overlay-prebuilt.css';

@layer utilities {
  .action-round-button {
    @apply flex h-12 w-12 items-center justify-center rounded-3xl border border-black bg-white p-2 shadow-md transition-opacity duration-150 ease-in-out hover:bg-[neutral-hight-pure-light];
  }
}

@layer utilities {
  .action-round-button {
    @apply flex h-12 w-12 items-center justify-center rounded-3xl border border-black bg-white p-2 shadow-md transition-opacity duration-150 ease-in-out hover:bg-[neutral-hight-pure-light];
  }
}

@layer base {
  image-cropper {
    img {
      display: inline;
    }
  }
}

body {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  pointer-events: none;
}
// input,
// textarea {
//   &:focus {
//     box-shadow: 0 0 0 2px color('neutral-hight-pure'),
//       0 0 0 4px color('brand-tertiary-pure'), 0 0 0 6px rgba(0, 0, 0, 0.04);
//     transition: 0.2s ease-out;
//   }
// }

[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.overlay-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  &--active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.custom-modal {
  &-sm {
    @apply max-w-sm;
  }

  &-md {
    @apply max-w-md;
  }

  &-lg {
    @apply max-w-2xl;
  }
}

.register-select-modal {
  max-width: rem(530px);
}

.resources-modal {
  max-width: rem(375px);
}

.resources-modal,
.register-select-modal {
  .modal-content {
    border: 0;
    border-radius: border-radius('2xl');
  }
}

.no-scroll {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: hidden;
}

.preview-modal {
  @apply ml-0 mr-4 max-w-fit items-stretch;

  .modal-content {
    @apply flex-row rounded-bl-none rounded-br-3xl rounded-tl-none rounded-tr-3xl;
  }

  @media (min-width: 768px) {
    @apply ml-auto mr-auto max-w-[843px];

    .modal-content {
      @apply rounded-lg;
    }
  }
}
